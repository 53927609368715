<template>
  <div class="form-group">
    <div class="form-group-wrap">
      <b-form-input
        :id="inputId"
        :size="size"
        class="app-input"
        :class="inputClasses"
        :value="value"
        :disabled="disabled"
        :placeholder="placeholder"
        trim
        :type="inputType"
        @input="onInput"
        @blur="onBlur"
      />

      <label class="form-group__label" :class="labelClasses" :for="inputId">
        {{ label }}

        <template v-if="tooltipTitle">
          <b-icon :id="tooltipIconId" class="tooltip-icon" icon="question-circle-fill" />
          <b-tooltip :target="tooltipIconId" :title="tooltipTitle" :variant="tooltipVariant" />
        </template>
      </label>

      <p v-if="withError" class="error-message">
        {{ errorMessage }}
      </p>
    </div>

    <p v-if="desc" class="form-group__desc">{{ desc }}</p>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    value: {
      type: [String, Number],
      default: "",
    },

    inputType: {
      type: String,
      default: "text",
    },

    required: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    withError: {
      type: Boolean,
      default: false,
    },

    errorMessage: {
      type: String,
      default: function () {
        this.$t("validation.required");
      },
    },

    tooltipTitle: {
      type: String,
      default: "",
    },

    tooltipVariant: {
      type: String,
      default: "light",
    },

    tooltipIconId: {
      type: String,
      default: function () {
        return String(Date.now() + Math.random());
      },
    },

    inputId: {
      type: String,
      default: function () {
        return String(Date.now() + Math.random());
      },
    },

    size: {
      type: String,
      default: "md",
    },

    borderColor: {
      type: String,
      default: "gray",
    },

    desc: {
      type: String,
      default: "",
    },
  },

  computed: {
    inputClasses() {
      const error = this.withError ? "error" : "";
      const borderColor = `border-${this.borderColor}`;

      return [error, borderColor];
    },

    labelClasses() {
      const required = this.required ? "required" : "";
      const error = this.withError ? "error-label" : "";
      const color = !this.withError ? `label-${this.borderColor}` : "";

      return [required, error, color];
    },
  },

  methods: {
    onInput(value) {
      this.$emit("input", value);
    },

    onBlur() {
      this.$emit("blur");
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  margin: 0.7rem 0 2.5rem 0;
  position: relative;

  &-wrap {
    position: relative;
  }

  &__desc {
    font-size: 12px;
    color: #777;
    margin-left: 20px;
    margin-bottom: 0;
    margin-top: 4px;
  }
}

.form-control {
  border-radius: 0.25rem;
  font-weight: 700;

  &:focus {
    border-color: $primary !important;
  }

  &:focus + label {
    color: $primary;
  }

  &:disabled {
    border: none;
  }
}

.form-control-md {
  padding-left: 1.4rem;
}

.form-group__label {
  font-size: 1.07rem;
  margin-left: 5px;
  padding: 0 5px;
  position: absolute;
  left: 10px;
  top: -15px;
  background-color: white;
  border-radius: 0.25rem;
  z-index: 1;

  .tooltip-icon {
    margin-bottom: 0.1rem;
  }
}
.form-group__label.label-gray {
  color: $gray-500;
}

.form-group__label.label-black {
  color: $black;
}

.form-group__label.required {
  &:after {
    content: "*";
    position: absolute;
    color: $danger;
    font-weight: bold;
    right: -5px;
  }
}

.error-message {
  position: absolute;
  font-size: 0.9rem;
  color: $danger;
  bottom: -19px;
  left: 10px;
  margin-left: 5px;
  padding: 0 5px;
  background-color: white;
  border-radius: 0.25rem;
  z-index: 1;
  white-space: nowrap;
}

.app-input.border-black {
  border-color: $black;
}

.app-input.error {
  border-color: $danger;
}

.error-label {
  color: $danger;
}
</style>
