<template>
  <div role="group" class="form-group" :class="groupClasses">
    <div class="form-group__label-wrapper">
      <label class="form-group__label" :class="labelClasses" :for="multiselectId">
        {{ label }}
      </label>
    </div>

    <Multiselect
      :id="multiselectId"
      class="app-multiselect"
      :class="[sizeClass, borderColorClass]"
      :value="withInternalSearch ? value : asyncValue"
      :options="withInternalSearch ? options : asyncOptions"
      :options-limit="optionsLimit"
      :multiple="multiple"
      :label="itemLabel"
      :group-label="groupLabel"
      :group-values="groupValues"
      :group-select="groupSelect"
      :track-by="trackBy"
      :open-direction="openDirection"
      select-label=""
      deselect-label=""
      selected-label=""
      :hide-selected="false"
      :disabled="disabled"
      :placeholder="placeholder"
      :close-on-select="!multiple"
      :allow-empty="allowEmpty"
      :internal-search="withInternalSearch"
      :loading="isLoading"
      @open="onOpen"
      @close="onClose"
      @search-change="findOptions"
      @select="selectValue"
      @remove="removeValue"
    >
      <template v-slot:singleLabel="props">
        <slot name="singleLabel" :scopeProps="props">
          <StatusItem v-if="isStatusType" :color="props.option.color" :label="props.option.label" />
        </slot>
      </template>

      <template v-slot:option="props">
        <slot name="option" :scopeProps="props">
          <StatusItem v-if="isStatusType" :color="props.option.color" :label="props.option.label" />
        </slot>
      </template>

      <template v-slot:noResult>
        {{ $t("component.select.listІsEmpty") }}
      </template>

      <template v-slot:noOptions>
        {{ $t("component.select.noDataToShow") }}
      </template>
    </Multiselect>

    <p v-if="withError && !value.length" class="error-message">
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import StatusItem from "@/components/StatusItem";

export default {
  name: "MultiselectElement",
  components: {
    Multiselect,
    StatusItem,
  },

  props: {
    multiselectId: {
      type: String,
      default: function () {
        return String(Date.now() + Math.random());
      },
    },

    value: {
      type: Array,
      default: () => [],
    },

    options: {
      type: Array,
      default: () => [],
    },

    label: {
      type: String,
      default: "",
    },

    itemLabel: {
      type: String,
      default: "label",
    },

    groupLabel: {
      type: String,
      default: "label",
    },

    placeholder: {
      type: String,
      default: "",
    },

    required: {
      type: Boolean,
      default: false,
    },

    withError: {
      type: Boolean,
      default: false,
    },

    errorMessage: {
      type: String,
      default: function () {
        this.$t("validation.required");
      },
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    noOptionsText: {
      type: String,
      default: function () {
        this.$t("component.select.listІsEmpty");
      },
    },

    allowEmpty: {
      type: Boolean,
      default: true,
    },

    groupSelect: {
      type: Boolean,
      default: false,
    },

    groupValues: {
      type: String,
      default: "",
    },

    withInternalSearch: {
      type: Boolean,
      default: true,
    },

    optionsLimit: {
      type: Number,
      default: Infinity,
    },

    resetAsync: {
      type: Boolean,
      default: false,
    },

    trackBy: {
      type: String,
      default: "id",
    },

    openDirection: {
      type: String,
      default: "bottom",
    },

    size: {
      type: String,
      default: "md",
    },

    type: {
      type: String,
      default: "",
    },

    borderColor: {
      type: String,
      default: "gray",
    },
  },

  data: () => ({
    isLoading: false,
    asyncValue: "",
    asyncOptions: [],
    isOpen: false,
  }),

  computed: {
    groupClasses() {
      return {
        error: this.withError && !this.value.length,
        "with-groups": this.groupValues,
      };
    },

    labelClasses() {
      return {
        required: this.required,
        "label-color": this.isOpen,
        "label-gray": this.borderColor === "gray" && !this.withError,
        "label-black": this.borderColor === "black" && !this.withError,
        "error-label": this.withError && !this.value.length,
      };
    },

    sizeClass() {
      return this.size ? [this.size] : "";
    },

    borderColorClass() {
      return `border-${this.borderColor}`;
    },

    isStatusType() {
      return this.type === "status";
    },
  },

  watch: {
    resetAsync() {
      this.asyncValue = "";
      this.asyncOptions = [];
    },
  },

  methods: {
    onOpen() {
      this.$emit("open");
      this.isOpen = true;
    },

    onClose() {
      this.isOpen = false;
    },

    selectValue(newItem) {
      this.$emit("select", newItem);

      if (this.withInternalSearch) {
        return;
      }

      this.asyncValue = newItem;
    },

    removeValue(itemToRemove) {
      this.$emit("remove", itemToRemove);

      if (this.withInternalSearch) {
        return;
      }

      this.asyncValue = null;
    },

    async findOptions(query) {
      this.isLoading = true;

      this.$emit("searchChange", query);

      this.isLoading = false;
    },
  },
};
</script>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.form-group {
  margin: 0.7rem 0 2.5rem 0;
  position: relative;

  &.error {
    .app-multiselect::v-deep {
      .multiselect__tags {
        border-color: $danger !important;
      }
    }
  }

  &.with-groups {
    .app-multiselect::v-deep {
      .multiselect__option {
        padding: 5px 12px 5px 40px;
      }
    }
  }
}

.label-color {
  color: $primary !important;
}

.form-group__label {
  font-size: 1.07rem;
  margin-left: 5px;
  padding: 0 5px;
  position: absolute;
  left: 10px;
  top: -14px;
  background-color: white;
  border-radius: 4px;
  z-index: 1;
}

.error-label {
  color: $danger;
}

.form-group__label.label-gray {
  color: $gray-500;
}

.form-group__label.label-black {
  color: $black;
}

.form-group__label.required {
  &:after {
    content: "*";
    position: absolute;
    color: $danger;
    font-weight: bold;
    right: -5px;
  }
}

.error-message {
  position: absolute;
  font-size: 0.9rem;
  color: $danger;
  bottom: -19px;
  left: 10px;
  margin-left: 5px;
  padding: 0 5px;
  background-color: white;
  border-radius: 0.25rem;
  z-index: 1;
}

.app-multiselect::v-deep {
  &.multiselect {
    min-height: 41px;
    border-radius: 0.25rem;
    color: $gray-800;

    .multiselect__tags {
      padding: 9px 40px 0 8px;
      min-height: 41px;
      border-radius: 0.25rem;
      font-weight: 700;
      background-color: $white;
    }

    &--active {
      z-index: unset;

      .multiselect__tags {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &--disabled {
      opacity: 1;

      .multiselect__tags {
        background-color: $gray-100;
        border: none;
      }

      .multiselect__select,
      .multiselect__current {
        background: none;
      }
    }
  }

  .multiselect__content-wrapper {
    border-color: $gray-400;
    min-height: 80px;
  }

  .multiselect__spinner {
    height: 36px;
    border-radius: 0.42rem;
  }

  .multiselect__option {
    padding: 5px 12px 5px 20px;
    min-height: 30px;
    line-height: 30px;
    font-size: 1rem;
    color: black;
    background-color: white;

    &:hover {
      color: black;
      background-color: $gray-100;
    }
  }

  .multiselect__option--group {
    padding: 7px 12px 7px 13px !important;
  }

  .multiselect__single {
    margin-bottom: 0;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: none;
  }

  .multiselect__tag {
    margin-top: 2px;
    margin-bottom: 0;
    color: black;
    font-size: 1rem;
  }

  .multiselect__tag-icon {
    &:after {
      color: black;
    }
    &:focus,
    &:hover {
      color: white;
      background-color: $danger;
      &:after {
        color: white;
      }
    }
  }

  .multiselect__placeholder {
    margin-bottom: 3px;
    padding-left: 11px;
    color: $gray-800 !important;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  .multiselect__input {
    margin-bottom: 0;
    padding-top: 3px;
    padding-left: 5px;
    font-size: 1rem;
    font-weight: bold;
  }
}

.app-multiselect.border-black::v-deep {
  &.multiselect {
    .multiselect__tags {
      border: solid 1px $black;
    }

    &--active {
      .multiselect__tags {
        border-color: $primary !important;
      }
    }
  }
}

.app-multiselect.border-gray::v-deep {
  &.multiselect {
    .multiselect__tags {
      border: solid 1px $gray-300;
    }

    &--active {
      .multiselect__tags {
        border-color: $primary !important;
      }
    }
  }
}

.lg::v-deep {
  .multiselect__select {
    top: 5px;
  }

  .multiselect__tags {
    padding: 11.5px 15px !important;
    min-height: calc(1.5em + 1.65rem + 4px) !important;
    font-weight: 700;

    .multiselect__single {
      font-weight: 700;
      font-size: 1.08rem;
    }
  }
}

.md::v-deep {
  .multiselect__single {
    padding-left: 11px;
  }
}
</style>
