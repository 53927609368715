<template>
  <div class="d-flex flex-row align-items-center">
    <div class="status-icon mr-3" :class="`bg-${color}`" />
    <span class="crop-text">
      {{ label }}
    </span>
  </div>
</template>

<script>
export default {
  name: "StatusItem",
  props: {
    color: {
      type: String,
      default: "secondary",
    },

    label: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.status-icon {
  height: 16px;
  min-width: 16px;
  border-radius: 2px;
}
</style>
